import React, { useState, useEffect } from 'react';
import { marked } from 'marked';
import CodeViewer from './CodeViewer';

const ContactPage = () => {
  const [contactInfo, setContactInfo] = useState('');

  useEffect(() => {
    fetch('./CONTACT.md')
      .then(response => response.text())
      .then(text => setContactInfo(marked.parse(text)))
      .catch(error => console.error(error));
  }, []);

  return (
    <div className="page-content">
      <h1 style={{marginBottom: '77px'}}>Feel free to contact me! 📧</h1>
      <CodeViewer content={contactInfo} label="johnyfernandes / CONTACT.md" link="https://github.com/johnyfernandes" />
    </div>
  );
};

export default ContactPage;