import React, { useState, useEffect } from 'react';
import { marked } from 'marked';
import './Homepage.css';
import CodeViewer from './CodeViewer';

const HomePage = () => {
  const [readme, setReadme] = useState('');

  useEffect(() => {
    fetch('./README.md')
      .then(response => response.text())
      .then(text => setReadme(marked.parse(text)))
      .catch(error => console.error(error));
  }, []);

  return (
    <div className="page-content">
      <h1 style={{marginBottom: '77px'}}>Hello there! 👋🏻</h1>
      <CodeViewer content={readme} label="johnyfernandes / README.md" link="https://github.com/johnyfernandes" />
    </div>
  );
};

export default HomePage;
