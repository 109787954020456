import React, { useState, useEffect, useRef } from 'react';
import './Sidebar.css';
import { Icon } from '@iconify/react';
import foto from './foto.jpeg';
import ContentArea from './ContentArea';

function Sidebar() {
  const [isActive, setIsActive] = useState(false);
  const [activePage, setActivePage] = useState('home'); // default active page
  const sidebarRef = useRef(); // Create a ref for the sidebar element
  const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString());

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };

  const handleItemClick = (page) => {
    setActivePage(page);
    setIsActive(false); // Close sidebar if needed
  };

  // Handle clicking outside the sidebar
  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    // Attach the event listener
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Remove the event listener on cleanup
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [sidebarRef]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Define the sidebar items here
  const sidebarItems = [
    { label: 'Start', icon: 'tabler:home', page: 'home' },
    { label: 'My Resume', icon: 'tabler:file-cv', page: 'resume' },
    { label: 'Contact Me', icon: 'tabler:brand-telegram', page: 'contact' },
  ];

  return (
    <>
      <div className={`sidebar ${isActive ? 'active' : ''}`} ref={sidebarRef}>
        <div className="sidebar-profile">
          <img src={foto} alt="Profile" className="sidebar-photo" />
          <h1 className="sidebar-name">João Fernandes</h1>
          <p className="sidebar-title">📊 Data Analyst 📊</p>
          <p className="sidebar-title">👨🏻‍💻 Software Engineer 👨🏻‍💻</p>
          <p className="sidebar-title">🕵🏼‍♀️ Market Researcher 🕵🏼‍♀️</p>
        </div>
        {sidebarItems.map(item => (
          <button
            key={item.label}
            className={`sidebar-item ${activePage === item.page ? 'active' : ''}`}
            onClick={() => handleItemClick(item.page)}
          >
            <Icon icon={item.icon} />
            {item.label}
          </button>
        ))}
        {/* Local Time Display */}
        <div className="local-time">{currentTime}</div>
      </div>
      <div className="sidebar-toggle" onClick={toggleSidebar} style={{ display: isActive ? 'none' : 'flex' }}>
        <Icon icon="tabler:menu-2" />
      </div>
      <ContentArea activePage={activePage} />
    </>
  );
}

export default Sidebar;
