import React, { useState, useEffect } from 'react';
import { marked } from 'marked';
import CodeViewer from './CodeViewer';

const ResumePage = () => {
  const [readme, setReadme] = useState('');

  useEffect(() => {
    fetch('./RESUME.md')
      .then(response => response.text())
      .then(text => setReadme(marked.parse(text)))
      .catch(error => console.error(error));
  }, []);

  return (
    <div className="page-content">
      <h1 style={{ marginBottom: '77px' }}>My Resume 📜</h1>
      <CodeViewer content={readme} label="johnyfernandes / RESUME.md" link="https://github.com/johnyfernandes" />
    </div>
  );
};

export default ResumePage;