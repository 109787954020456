import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { marked } from 'marked'; // Import marked library
import './CodeViewer.css';
import usFlag from './us.jpg';
import ptFlag from './pt.jpg';

const CodeViewer = ({ label, link }) => {
  const [language, setLanguage] = useState('en');
  const [content, setContent] = useState('');
  const [filename, setFilename] = useState(label.split('/').pop());

  useEffect(() => {
    const fetchContent = async () => {
      const newFilename = filename.replace(/(_pt)?\.md$/, (language === 'en' ? '.md' : '_pt.md'));
      const filePath = `/markdown/${newFilename.trim()}`;
      console.log(`Fetching: ${filePath}`); // Log the path being fetched
      try {
        const response = await fetch(filePath);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`); // Handle HTTP errors
        }
        if (response.headers.get('Content-Type').includes('text/html')) {
          console.error('Fetched content is HTML, expected markdown');
          setContent('Error: Fetched content is HTML, expected markdown');
          return;
        }
        const markdown = await response.text();
        const html = marked.parse(markdown); // Converts markdown to HTML
        setContent(html);
      } catch (error) {
        console.error('Error fetching markdown content:', error);
        setContent('Error loading content.');
      }
    };
  
    fetchContent();
  }, [language, filename]);

  const Label = link ? 'a' : 'div';

  const switchLanguage = (lang) => {
    setLanguage(lang);
    setFilename(prevFilename => prevFilename.replace(/(_pt)?\.md$/, (lang === 'en' ? '.md' : '_pt.md')));
  };

  const renderLabel = () => {
    return (
      <span className="github-style-label">
        <span className="path">johnyfernandes /‎ ‎ </span>
        <span className="filename">{filename}</span>
      </span>
    );
  };

  return (
    <div className="github-style-container">
      <Label
        href={link || undefined}
        target={link ? "_blank" : undefined}
        rel={link ? "noopener noreferrer" : undefined}
      >
        {renderLabel()}
      </Label>
      <div className="code-viewer-language-switcher">
        <img src={usFlag} className="flag" alt="US" onClick={() => switchLanguage('en')} />
        <img src={ptFlag} className="flag" alt="PT" onClick={() => switchLanguage('pt')} />
      </div>
      <div className="code-viewer" dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
};

CodeViewer.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string,
};

export default CodeViewer;