import React from 'react';
import HomePage from './Homepage.js';
import ResumePage from './ResumePage.js';
import ContactPage from './ContactPage.js';
import './ContentArea.css';

const ContentArea = ({ activePage }) => {
    // The logic here assumes you have components for each page
    const renderPageContent = () => {
      switch (activePage) {
        case 'home':
          return <HomePage />;
        case 'resume':
          return <ResumePage />;
        case 'contact':
          return <ContactPage />;
        default:
          return <HomePage />;
      }
    };
  
    return (
      <div className="content-area">
        {renderPageContent()}
      </div>
    );
  };
  
  export default ContentArea;
